
import Vue from 'vue';
import { Header } from '@/types/DataTable';
import { User, UserStatus, UserRole } from '@/types/User';
import intl from '@/utils/intl';
import { empty, each } from '@/utils/helpers';
import Http, { HttpError } from '@/utils/Http';

import ConfirmDialog from '@/components/dialogs/confirm.vue';
import Pagination from '@/components/pagination.vue';
import UserActionModal from '@/components/users/action-modal.vue';

export default Vue.extend({
  name: 'UsersModule',
  props: {
    title: String
  },
  components: {
    ConfirmDialog,
    Pagination,
    UserActionModal
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      pageItemsTotal: 0,
      pageTotal: 0,
      statuses: {
        0: intl.t('statuses.inactive'),
        1: intl.t('statuses.active')
      } as Record<number, string>,
      headers: [] as Array<Header>,
      userStatuses: [] as Array<UserStatus>,
      userRoles: [
        { id: 1, name: intl.t('views.users.roles.admin') },
        { id: 2, name: intl.t('views.users.roles.operator') }
      ] as Array<UserRole>,
      users: [] as Array<User>,
      usersLoading: false,
      usersSelected: []
    }
  },
  computed: {
    pageTitle(): string {
      return empty(this.title) ? '' : intl.t(this.title);
    },
    addButton(): string {
      return intl.t('buttons.add');
    },
    editButton(): string {
      return intl.t('buttons.edit');
    },
    deleteButton(): string {
      return intl.t('buttons.delete');
    }
  },
  methods: {
    rowClick(item: User, { isSelected, select }: { isSelected: boolean, select: (v: boolean) => void }): void {
      if (item.username !== this.$store.getters.user.username && !this.usersLoading) {
        select(!isSelected);
      }
    },
    pagination(val: Record<string, number>): void {
      this.pageCount = val.pageCount;
      this.pageTotal = val.itemsLength;
      this.pageItemsTotal = val.pageStop - val.pageStart;
    },
    pageChange(val: number): void {
      this.page = val;
    },
    itemsPerPageChange(val: number): void {
      this.itemsPerPage = val;
    },
    setLabelName(name: string): string {
      return intl.t(`views.users.${name}Label`);
    },
    async getUsers(): Promise<void> {
      this.usersLoading = true;
      this.users = [];
      this.usersSelected = [];
      try {
        const response = await (new Http()).get(`/v1/users`);
        if (!empty(response.data.data)) {
          this.users = response.data.data;
        }
      } catch (error) {
        const status = (error as HttpError).response?.status as number;
        if (status < 404) {
          await this.$router.push({ name: 'dashboard' });
        }
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.usersLoading = false;
    },
    async deleteUser(): Promise<void> {
      if (this.usersSelected.length === 1) {
        const user: User = this.usersSelected[0];
        if (
          await (this.$refs.ConfirmDialog as InstanceType<typeof ConfirmDialog>).open(
            intl.t('views.users.confirmDeleteTitle'),
            intl.t('views.users.confirmDeleteText', user.name),
            {
              confirmBtn: intl.t('buttons.delete')
            }
          )
        ) {
          this.usersLoading = true;
          try {
            await (new Http()).delete(`/v1/users/${user.user_guid}`);
            const message = intl.t('views.users.deleteText', user.name);
            this.$root.$children[0].$children[0].setFlash('success', message);
            await this.getUsers();
          } catch (error) {
            const err = (error as HttpError).response?.data.error;
            this.$root.$children[0].$children[0].setFlash('error', err.message);
          }
          this.usersLoading = false;
        }
      }
    }
  },
  async created() {
    // Only Admin role
    if (this.$store.getters.user.role !== 1) {
      await this.$router.push({ name: 'dashboard' });
    }
  },
  async mounted() {
    each(this.statuses, (name: string, id: number) => {
      this.userStatuses.push({
        id: Number(id),
        name: name
      });
    });
    // Load data
    this.usersLoading = true;
    await this.getUsers();
    // Set headers
    this.headers.push({
      dtFilter: {
        type: 'text'
      },
      text: this.setLabelName('name'),
      value: 'name'
    });
    this.headers.push({
      dtFilter: {
        type: 'text'
      },
      text: this.setLabelName('username'),
      value: 'username'
    });
    this.headers.push({
      dtFilter: {
        type: 'text'
      },
      sortable: false,
      text: this.setLabelName('phone'),
      value: 'phone'
    });
    this.headers.push({
      dtFilter: {
        type: 'text'
      },
      sortable: false,
      text: this.setLabelName('position'),
      value: 'position',
      hidden: true
    });
    this.headers.push({
      dtFilter: {
        type: 'select',
        optionType: 'text',
        options: this.userRoles,
        value: 'name',
        text: 'name'
      },
      text: this.setLabelName('role'),
      value: 'role_name'
    });
    this.headers.push({
      dtFilter: {
        type: 'select',
        optionType: 'boolean',
        options: this.userStatuses,
        value: 'id',
        text: 'name'
      },
      align: 'center',
      text: this.setLabelName('status'),
      value: 'status'
    });
  }
});
