
import Vue, { PropType } from 'vue';
import { VForm } from 'vuetify/types/custom';
import { Tenant } from '@/types/Tenant';
import { User, UserRole, UserStatus } from '@/types/User';
import { empty, each } from '@/utils/helpers';
import intl from '@/utils/intl';
import validators from '@/utils/validators';
import Http, { HttpError, HttpMethod } from '@/utils/Http';

export default Vue.extend({
  name: 'UserActionModal',
  mixins: [validators],
  props: {
    small: Boolean,
    dark: Boolean,
    color: String,
    cssClass: String,
    title: String,
    item: Object as PropType<User | undefined>,
    userStatuses: Array as PropType<Array<UserStatus>>,
    userRoles: Array as PropType<Array<UserRole>>
  },
  data() {
    return {
      modal: false,
      modalTitle: '',
      submitLoading: false,
      // Tenants
      tenantsLoading: false,
      tenants: [],
      // Errors
      nameErrors: [],
      usernameErrors: [],
      passwordErrors: [],
      positionErrors: [],
      phoneErrors: [],
      // Fields
      tenant: [] as Array<string>,
      name: null as string | null,
      role: null as number | null,
      username: null as string | null,
      password: null as string | null,
      position: null as string | null,
      phone: null as string | null,
      status: null as number | null
    }
  },
  computed: {
    allUserRoles(): Array<UserRole> {
      return this.userRoles.filter((itm) => itm.id >= this.$store.getters.user.role);
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    okButton(): string {
      return intl.t('ok');
    }
  },
  watch: {
    async modal(status): Promise<void> {
      this.tenant = [];
      if (status) {
        if (this.item && !empty(this.item)) {
          this.modalTitle = this.title + ' ' + this.item.name.trim();
        } else {
          this.modalTitle = this.title;
        }
        await this.getTenants();
        this.tenant.push(this.$store.getters.user.tenant.tenant_guid);
        if (this.item && !empty(this.item)) {
          this.name = this.item.name;
          this.role = this.item.role;
          this.username = this.item.username;
          this.position = this.item.position;
          this.phone = this.item.phone;
          this.status = this.item.status;
          if (this.item.tenants) {
            each(this.item.tenants, (tenant: Tenant) => {
              if (!this.tenant.includes(tenant.tenant_guid)) {
                this.tenant.push(tenant.tenant_guid);
              }
            });
          }
        }
      }
    }
  },
  methods: {
    empty,
    setLabelName(name: string): string {
      return intl.t(`views.users.${name}Label`);
    },
    async getTenants(): Promise<void> {
      this.tenantsLoading = true;
      this.tenants = [];
      try {
        const response = await (new Http()).get(`/v1/tenants`);
        if (!empty(response.data.data)) {
          this.tenants = response.data.data;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.tenantsLoading = false;
    },
    close(): void {
      this.modal = false;
      (this.$refs.form as VForm).reset();
    },
    async submit(): Promise<void> {
      if((this.$refs.form as VForm).validate()) {
        this.submitLoading = true;
        let method: HttpMethod, url: string, message: string;
        if (this.item && !empty(this.item)) {
          method = 'put';
          url = `/v1/users/${this.item.user_guid}`;
          message = intl.t('views.users.updateText', String(this.name));
        } else {
          method = 'post';
          url = `/v1/users`;
          message = intl.t('views.users.createText', String(this.name));
        }
        const data = {
          name: this.name,
          username: this.username,
          password: this.password,
          role: this.role,
          status: this.status,
          position: this.position,
          phone: this.phone,
          tenant: this.tenant
        };
        try {
          const result = await (new Http()).request({
            method: method,
            url: url,
            data: JSON.stringify(data)
          });
          this.$root.$children[0].$children[0].setFlash('success', message);
          this.$emit('onSuccess', result.data);
          this.close();
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.$root.$children[0].$children[0].setFlash('error', err.message);
          if ('name' in err) {
            this.nameErrors = err.name;
          }
          if ('username' in err) {
            this.usernameErrors = err.username;
          }
          if ('password' in err) {
            this.passwordErrors = err.password;
          }
          if ('position' in err) {
            this.positionErrors = err.position;
          }
          if ('phone' in err) {
            this.phoneErrors = err.phone;
          }
        }
        this.submitLoading = false;
      }
    }
  }
});
